.notes-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .note-card {
    background: #F1F7FF;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .note-content {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .note-details {
    font-size: 12px;
    color: #555;
  }
  
  .note-author, .note-timestamp {
    font-weight: bold;
  }
  