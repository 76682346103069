.MuiIconButton-root {
    margin-left: 0;
}
.MuiMenu-paper {
    min-width: 200px;
}
.MuiMenuItem-root {
    padding: 8px 16px;
}
button {
    size: 15px;
}
#imgprof {
    margin-top: 4px;
    display: flex;
    width: 3%;
    align-items: center;
    justify-content: flex-start;
    position: static;
    box-sizing: border-box;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    vertical-align: baseline;
    text-align: left;
    padding: 3px;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-right: 7px;
    margin-left: 15px;
    color: inherit;
}