/* ChatTable.css */

.chat-room {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    direction: rtl;
}
 #user-id-display.admin-user-id{
    background-color: aqua;
    padding: 20px;
}
#user-id-display{
    border: 10px;
}

/* 
#messages {
    max-height: 100%;
    overflow-y: auto;
    margin-bottom: 20px;
}
.st.msg.sender{
    background-color: #1e88e5;
}
.message {
    background-color: #e1f5fe;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}
.msan{
    background-color: aqua;
} */
/* .mcon{ */
    /* background-color: blueviolet;
}
.message {
    align-self: flex-end;
    background-color: #c8e6c9;
}
.masg.msg.sender{
 background-color: aquamarine;   
}
.message strong .sender {
    font-weight: bold;

    margin-bottom: 5px;
} */
.masg{
    border-radius: 15px;
    background-color:'black';
   
}
form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

input[type="text"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}
.msan{
    border: 30px;
    color: red;
}
/* 
button[type="submit"] {
    padding: 10px 20px;
    background-color: #42a5f5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
} */

/* button[type="submit"]:hover {
    background-color: #1e88e5;
} */

@media (max-width: 600px) {
    .chat-room {
        padding: 10px;
    }

    input[type="text"] {
        width: 100%;
    }

    button[type="submit"] {
        width: 100%;
        margin-top: 10px;
    }
}
