.payments-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 645px;
    background: #F1F7FF;
    border-radius: 15px;
  }
  .scroll-container {
    display: flex;
    align-items: center; 
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
  }
  #payments-title {
    margin-top: 27px;
    font-family: 'CustomFont';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #002046;
    margin-right: 20px; 
  }
  .payments-list {
    display: flex;
    flex-direction: row;
  }
  .payment-option {
    display: flex; 
    align-items: center; 
    margin-right: 60px;
    margin-top: 15px;
  }
  .payment-content {
    display: flex; 
    align-items: center; 
  }
  .rounded-checkbox {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid black;
    appearance: none;
    cursor: pointer;
    margin-right: 10px; 
  }
  
  .payment-text {
    font-family: 'CustomFont';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #002046;
  }




