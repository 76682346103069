
/* צבעים לסטטוס */
.First {
    background-color: #FFF177;
  }
  .InitialConversation {
    background-color: #71E29E;
  }
  .characterization {
    background-color:  #FF7361;
  }
  .toSend {
    background-color:  #FFB4AA;
  }
  .sent {
    background-color:#FF4131;   
  }
  .Tracking {
    background-color: #0084FF;  
  }
  .Invoicing{
  background-color: #B659FF;
  }
  .Creation{
  background-color: #FF1B6D;
  }
  .closed{
  background-color: #E6E6E6;
  }
  .phone{
      width: 10%; /* או כל ערך אחר שתרצה, לדוגמה 100% */
  }
  .email{
    width: 15%;
  }
  
  
  body {
    font-family: 'Ping HL', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #F1F7FF; 
    color: #002046;
  }
  
  /* Page container */
  .page-container {
    font-family: 'CustomFont'!important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px; 
    margin-bottom: 30px; 
  }
  
  /* Lead management container */
  .lead-management-container {
    width: 90%;
    background-color: #FFFFFF; /* White background for the container */
    border-radius: 15px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
  }
  .lead-management-title{
      display: flex;
      align-items: center;
      justify-content: center; /* This centers the title horizontally */
      width: 100%; /* Makes sure the container takes the full width */
      height: 100px; /* Adjust this height as needed */
     /* margin-top: 0; */
     font-size: 28px;
     height: 30px;
     text-align: center;
  }
  
  /* Table container styles */
  .table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    margin-top: 0;
  }
  
  /* Table styles */
  .table {
    border-collapse: collapse; /* Collapse borders between cells */
    table-layout: auto; /* Allow each column to have its own width based on content */
    width: 100%;
    border-spacing: 0; /* No spacing between cells */
    border-top: 1px solid #D9D9D9; /* Border above the table */
  }
  
  .table th,
  .table td {
    font-size: 13.5px;
    font-weight: 400;
    line-height: 12.01px;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
    height: 50px;
    padding: 0px;
    color: #002046;
    border: none; /* No border inside cells */
    border-bottom: 1px solid #D9D9D9; /* Bottom border between rows */
    vertical-align: middle; /* אנכי אמצעי */
    word-break: break-word; /* למניעת קטיעת מילים */
  }

  
  /* Header cell */
  .table th {
    color: #002046;
    background-color: transparent; /* Transparent background for header */
    position: relative;
    height: 21px;
  }
  
  /* Border between header cells */
  .table th:not(:last-child) {
    /* border-right: 1px solid #D9D9D9; */
    padding: 8px;
  }
  
  /* Status button */
  .status-button {
    font-size: 15px;
    font-weight: 400;
    line-height: 12.01px;
    text-align: center;
    width: 100%;
    background-color: #002046;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .status-button:hover {
    background-color: #004080;
  }
  
  /* Circle button */
  .circle-button {
    width: 28px;
    height: 28px;
    border: 1px solid #002046;
    background-color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .circle-button.clicked {
    background-color: #002046;
    border-color: #FFFFFF;
  }
  
  /* עיצוב סלקט של סטטוס */
  .table td select {
    width: 100%;
    height: 100%;
    border: none;
    font-size: inherit;
    text-align: center;
    font-family: "CustomFont"!important;
  }
  
  .status-select {
    width: 100%;
    border: none;
    background: none;
    font-size: inherit;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    appearance: none; /* הסרה של עיצוב דפדפן ברירת מחדל */
    -webkit-appearance: none; /* הסרה של עיצוב דפדפן ברירת מחדל */
    -moz-appearance: none; /* הסרה של עיצוב דפדפן ברירת מחדל */
  }
  
  .status-select:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* הוספת אפקט בעת המיקוד */
  }
  
  .status-select option {
    background: none;
  }
  
  .status-select:focus {
    animation: expand 0.3s ease-in-out;
  }
  
  .lead-management-title {
    font: 'Ping HL';
    font-size: 32px;
    font-weight: 700;
    line-height: 72.45px;
    text-align: center;
    margin-bottom: 20px;
    color: #002046;
  }
  
  #swal-input4 {
    width: 63%;
    margin-top: 7px;
    height: 50px;
  }
  
  .swal2-input {
    height: 80px;
    margin: 4px;
  }
  
  .select {
    background-color: #ffffff;
  }
  
  .swal2-select {
    width: 100%;
    padding: 0.75em;
    font-size: 1em;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    margin-top: 0.5em;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .swal2-select option {
    background-color: white;
  }
  
  .swal2-select .default-option {
    background-color: lightgray;
  }
  /* #swal-input9{
    width: 63%;
    height: 10%;
  } */
  #swal-input7{
    width: 63%;

  }
  
  @keyframes expand {
    0% {
      transform: scaleY(0.9);
    }
    100% {
      transform: scaleY(1);
    }
  }
  
  /* עיצוב הערות */
  .notes-container {
    display: none; /* מוסתר במצב רגיל */
    flex-direction: column;
    padding: 10px;
    border-top: 1px solid #D9D9D9;
    background-color: #F9F9F9;
  }
  
  .notes-container.show {
    display: flex; /* מוצג כאשר יש מחלקת show */
  }
  
  .note-item {
    padding: 5px 0;
    border-bottom: 1px solid #D9D9D9;
  }
  
  .note-item:last-child {
    border-bottom: none;
  }
  
  .notes-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  
  .notes-button i {
    margin-right: 5px;
  }
  
  /* עיצוב נוסף לתאים */
  .table td {
    position: relative;
  }
  
  .notes-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 20px;
  }
  
  /* אנימציה */
  @keyframes slideDown {
    from {
      max-height: 0;
    }
    to {
      max-height: 500px; /* ערך גובה מקסימלי */
    }
  }
  
  @keyframes slideUp {
    from {
      max-height: 500px;
    }
    to {
      max-height: 0;
    }
  }
  
  .notes-container.show {
    animation: slideDown 0.3s ease forwards;
  }
  
  .notes-container.hide {
    animation: slideUp 0.3s ease forwards;
  }
  .button1 {
    font-family: 'CustomFont'!important;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 15px 30px;
    font-size: 12px;
    font-weight: 700;
    color: hsl(0, 0%, 100%);
    border-radius: 7px;
    text-transform: uppercase;
    transition: all 0.1s ease-in-out;
    position: relative;
    background-color: #ff0081;
    box-shadow: rgba(233, 30, 99, 0.5);
  }
  
  .button1::after,
  .button1::before {
    transition: all 0.2s ease-in-out;
  }
  
  .button1::before {
    z-index: -1;
    position: absolute;
    content: "";
    left: -2em;
    right: -2em;
    top: -2em;
    bottom: -2em;
    background-repeat: no-repeat;
    background-image: radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, white 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 20%, transparent 20%),
      /*  */
        radial-gradient(circle, transparent 10%, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, white 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, #ff0081 20%, transparent 20%),
      radial-gradient(circle, #ff0081 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, white 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
      10% 10%, 18% 18%, 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%,
      20% 20%;
    background-position: 18% 40%, 20% 31%, 30% 30%, 40% 30%, 50% 30%, 57% 30%,
      65% 30%, 80% 32%, 15% 60%, 83% 60%, 18% 70%, 25% 70%, 41% 70%, 50% 70%,
      64% 70%, 80% 71%;
  }
  
  .button1:hover::before {
    background-position: 5% 44%, -5% 20%, 7% 5%, 23% 0%, 37% 0, 58% -2%, 80% 0%,
      100% -2%, -5% 80%, 100% 55%, 2% 100%, 23% 100%, 42% 100%, 60% 95%, 70% 96%,
      100% 100%;
    background-size: 0% 0%;
    transition: background-position 0.5s ease-in-out,
      background-size 0.75s ease-in-out;
  }
  #select_customer{
    width: 63%;
    /* height: 100%; */
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    
  }
  
  .pagination button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
    color: #000;

  }
  
  .pagination button:disabled {
    color: #ddd;
    cursor: not-allowed;
  }
  
 
  .icon {
    font-size: 12px; 
  }
  .add-lead-button,
.convert-lead-button {
  font-family: 'CustomFont'!important;
  display: flex;
  align-items: center;
  border: none;
  background-color: white;
  color: #636363;
  font-size: 15px;
  cursor: pointer;
}

.add-lead-button span.add,
.convert-lead-button span.add {
  margin-left: 5px;
}

tfoot td {
  text-align: right; /* Ensure content is aligned to the right */
  padding: 10px 0;
  color: #636363;
}

tfoot div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px; /* Space between the buttons */
}
#swal-input100{
  width: 63%;
  height: 50px;
}
#swal-input99{
  width: 63%;
  height: 80px;
}
.select{
  font-family: 'CustomFont'!important;
  font-weight: 400;
 
}
.select2{
  font-family: 'CustomFont'!important;
  font-weight: 400;
  text-align: right;
}
.custom-select {
  appearance: none; /* Remove default arrow */
  color: #000000;
  font-weight: 400;

  /* -webkit-appearance: none;
  -moz-appearance: none; */
}
.my-confirm-button{
  background-color: #00397C;
}
  