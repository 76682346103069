.user-table-container {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 47px;
}

.table-title {
  font-weight: 700;
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

table {
  margin-top: 4px;
  border-top: 1px solid #d9d9d9;
  width: 100%;
  border-collapse: collapse; 
  border-spacing: 0 10px;
}

th, td {
  padding: 12px;
  text-align: right;
}

th {
  background-color: #ffffff;
  /* text-align: center; */
  /* font-weight: bold; */
  font-weight: 700;
}

.table-row {
  border-top: 1px solid #d9d9d9; 
}

.icon-cell {
  text-align: center;
}

.icon {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.user-icon{
  margin-right: 40px;
  height: 25px;
  width: 25px;  
}
td{
  font-weight: 400;
  font-size: 15px;
}

.btn-primary {
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #34974d;
  background-color: #3aa856;
}

.btn-primary, .button__icon, .button__text {
  transition: all 0.3s;
}

.btn-primary .button__text {
  transform: translateX(30px);
  color: #fff;
  font-weight: 600;
}

.btn-primary .button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: #34974d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary .svg {
  width: 30px;
  stroke: #fff;
}

.btn-primary:hover {
  background: #34974d;
}

.btn-primary:hover .button__text {
  color: transparent;
}

.btn-primary:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.btn-primary:active .button__icon {
  background-color: #2e8644;
}

.btn-primary:active {
  border: 1px solid #2e8644;
}
.textt{
  font-family: "CustomFont" !important;
}
body{
  font-family: "CustomFont" !important;

}




