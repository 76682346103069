.table-container-categories {
  margin: 0 auto;
  width: 50%;
  text-align: center;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px;
  direction: rtl;
  border-top: 1px solid #F1F7FF;
  border-radius: 10px;
  overflow: hidden;

}

.styled-table thead tr {
  background-color: white;
  color: black;
  font-weight: bold;
  
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
  text-align: center;

}
.styled-table th:nth-child(4)
{
  border-top-left-radius:10px ;
}


.styled-table th:nth-child(5),
.styled-table td:nth-child(5) {
  background-color: transparent !important;
  border: none !important;

}


.styled-table th:nth-child(5) {
  display: none;
}

.styled-table td:nth-child(5) {
  background-color:#F1F7FF !important;
}
.styled-table tbody tr {
  background-color: white;
  position: relative;
}

.styled-table tbody tr:last-of-type td:nth-child(5) {
  border-bottom: none !important;
}

.styled-table tbody tr:last-of-type td:nth-child(5) {
  display: none;
}


#tdAddCategory {
  text-align: right;
  color: gray;
}

#textAddCategory {
  margin-right: 3%;
}

#addCategoryButton {
  font-size: larger;
  cursor: pointer;
}

.styled-table .edit-icon-cell {
  display: none;
}

.styled-table tbody tr:hover .edit-icon-cell {
  display: table-cell;
}

.styled-table button {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
}
.edit-icon-cell{
  background: none;
}

.styled-table button i {
  font-size: 1.2em;
  vertical-align: middle;
}

.styled-table .col-edit {
  width: 10%;
}

#aa{
  border: none !important;
  background-color: #F1F7FF !important;

}

