body {
  font-family: 'CustomFont' !important;
  font-weight: 700;


}

.styleProject {
  background-color: white;
  margin-right:3%;
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2%;
}

.styleProject2 {
  background-color: white;
  margin-right:2%;
  width: 101%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 2%;

}

table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

caption {
  font-weight: bold;
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;

}

table thead td {
  text-align: center;
}

table thead th {
  text-align: center;
}

th {
  text-align: center;
  font-weight: 700;

}

.filter {
  display: flex;
  /* Use flexbox for centering */
  align-items: center;
  /* Vertically center items */
  justify-content: center;
  /* Horizontally center items */
}

.btn {
  padding: 5px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}

.button {
  box-sizing: border-box;

  position: absolute;
  width: 30px;
  height: 30px;
  left: 1825px;
  top: 325px;

  border: 1px solid #002046;

}

.vector {
  padding-right: 10px;
  padding-left: 10px;
}

.title {
  text-align: center;

}

.titleFinish {
  text-align: center;

}

.styleProject {
  border-radius: 15px;
}

.filters {
  width: 100%;

}

.filter {
  align-items: center;
  margin-right: 3px;
  width: 10%;
}

#buttonClear {
  padding-right: 38%;
  border-radius: 20px;
}

.edit-dialog-textfield {
  margin-bottom: 16px;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 20px;
}

.filter-input {
  width: 60px;
  height: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.filter-input:focus {
  outline: none;
}

.styleProject .row td {
  position: relative;
}

.links-column {
  width: 100%;
}

.pagination .icon {
  vertical-align: middle;
}

.v {
  margin-right: -1%;
}

.circleV {
  padding-right: 12%;
}

#trash {
  margin-right: 3px;
}
.select:focus {
  outline: none;
}
.selectAll{
  border-radius: 4px;
  width:150px;
  font-size: 14px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}


.section{
  width: 100px; 
}
.loading{
  margin-right:100%;
}