body{
  font-family: 'CustomFont'!important;
}

.project-details {
    padding-left: 50%;
    background-color: #F1F7FF; 
    font-size: 15px;
    font-weight: 400;
    width: 100%; 
    height: 100%; 
    border-radius: 15px; 
    box-sizing: border-box; 
  }
  
  .project-details h2 {
    margin-bottom: 10px; 
  }
  
  .text  {
    width: 100%;
    margin-bottom:40%;
    display: inline-block;
    white-space: nowrap;
  }
#textPaid{
  padding-top:15%;
}
  .project-wrapper {
    display: flex;
    justify-content: space-between; 
  }
  
  .project-column {
    width: 48%; 
  }
  #projectDetails{
    Width:700px;
    Height:266px;
    margin-right: 3%;
  }
  #projectDetail{
  Width:
  221px;
  Height:
   362px;


  }
.buttonCircle{
width: 38px;
height: 38px;
border-radius: 35px;
text-align: center;
  }
  .project-column{
    margin-right: 3%;
  }
  .inline-text {
    display: inline-block;
    white-space: nowrap;
  }
  .project-column#payment {
    width: 100%;
  }

#payment{
  margin-top: -11%;
}

