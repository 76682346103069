.nav-container {
    width: 100%;
    direction: rtl;
    margin-top: 30px;
    margin-right: 30px;
}

.nav {
    display: flex;
    .user-table-container {
        background-color: #fff;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 20px;
      }
      
      .table-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
      }
      
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 10px; /* רווחים בין השורות */
      }
      
      th, td {
        border: none; /* אין קווים בין העמודות */
        padding: 12px;
        text-align: center;
      }
      
      th {
        background-color: #f0f0f0;
        font-weight: bold;
      }
      
      .table-row {
        border-top: 1px solid #d9d9d9; /* קווי הפרדה בין השורות */
      }
      
      .icon-cell {
        text-align: center;
      }
      
      .icon {
        margin-right: 5px;
      }
        justify-content: center;

}

.nav-list {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
}

.nav-item {
  margin-top: 55px;
    margin: 0 15px;
    padding: 10px 0;
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    

}

.nav-item a {
    text-decoration: none;
    color: #000;
}



.active {
    border-bottom: 3px solid #002046;
}

#img {
  margin-top: 30px;
  margin-left: 30px;
    width: 179px;
    height: 38px;
    top: 55px;
    left: 105px;
    /* margin: 15px 12px; */
    align-items:center ;
    gap: 0px;
    /* opacity: 0px; */

}
#imgandnav {
    direction: rtl;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
}
