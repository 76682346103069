
.form-container {
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  direction: rtl !important;

}

.form-container h1 {
  color: black;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  text-align: right;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  text-align: right;
}

input[type="text"],
input[type="number"] {
  width: calc(100% - 20px); /* כדי לקחת בחשבון את הגבול הפנימי */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.submit-button {
  background-color: #F1F7FF;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #b2b8bf;
}

.message {
  color: red;
  margin-top: 20px;
}

.input-label {
  display: inline-block;
  width: 40%;
  text-align: right;
  direction: rtl !important;
  margin-right: 10px;
}

.input-wrapper {
  display: inline-block;
  width: 55%;
  vertical-align: top;
}

#closeAdd {
  position: absolute;
  top: 0px;
  left: 0px;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding-top: 6px;
}

#closeAdd:hover {
  background-color: red;
  
  color: white;
}
#workerSelect,
#buttonSubmit,
#categoryName,
#weeksRequired{
  font-family: 'CustomFont';
}