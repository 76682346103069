body {
  background-color: #F1F7FF;
  font-family: 'CustomFont'!important;
 
}

.main-project-container {
  margin-top:1.5%;
  display: flex;
  direction: rtl;
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
}

.project-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}
.customerProjects {
  padding-left: 40px;

}

.circle {
  Width: 34px;
  Height:34px;
  border-radius: 20px;
  background-color: #F1F7FF;
  border: 2px solid #002046; 
}

#buttonProject {
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background-color: white;
}

.buttonCircle {
  width: 38px;
  height: 38px;
  border-radius: 35px;
  border: 2px solid #002046; 
  text-align: center;
  align-items: center;
  background-color: transparent; 
}

.buttonCircle svg {
  color: #002046; /* ודא שהצבע של האייקון תואם לצבע המסגרת */
}



    
