@font-face {
	font-family: 'CustomFont';
	src: url("../public/Fonts/WF-039261-012388-002673-01198636e676e4eb7f3579a996093698.woff2") format("woff2");

}


:root {
  /* font-synthesis: none; */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  direction: ltr !important;
  margin: 0;
  padding: 2rem;
  min-width: 320px;
  min-height: 100vh;
  background-color: var(--surface-card);
  font-family: 'CustomFont'!important;
  color: #002046;


  
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
